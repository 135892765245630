export default [
  {
    path: 'aboutus',
    label: 'ABOUT US',
  },
  {
    path: 'menu',
    label: 'MENU',
  },
  {
    path: 'detail',
    label: 'DETAIL',
  },
  {
    path: 'staff',
    label: 'STAFF',
  },
  {
    path: 'access',
    label: 'ACCESS',
  },
  // {
  //   path: 'qanda',
  //   label: 'Q&A',
  // },
];
