/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';
// import logo from 'assets/images/logo.png';
import logo from 'assets/svg/logo.svg';
import logoWhite from 'assets/images/logo-white.png';

export default function Logo({ isWhite }) {
  return (
    <Link
      path="/"
      sx={{
        variant: 'links.logo',
        // ml: '38px',
      }}
    >
      <Image src={isWhite ? logoWhite : logo} variant="logo" alt="logo" sx={{height: ['22px', '42px']}}/>

    </Link>
  );
}
