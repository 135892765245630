/** @jsx jsx */
import { jsx, Box, Text, Image, Flex } from 'theme-ui';
import { Link } from 'components/link';
import { footerNav } from './footer.data';
import { rgba } from 'polished';
import logo2 from 'assets/images/logo2.svg'
import instagram from 'assets/images/instagram.svg'

export default function Footer() {
  return (
    <Box as="footer" variant="layout.footer" sx={{mt: [0]}}>
      <div sx={{maxWidth: ['auto', '90%'], margin: '0 auto'}}>
        <Box sx={styles.footerTopInner}>
          <Box>
            <Image src={logo2} alt="logo" sx={{width: ['110px', 'auto']}}/>
          </Box>
          <Box sx={styles.footerNav}>
            {footerNav.map(({ path, label }, i) => (
              <div key={i}>
                <Link path={path} key={i} label={label} variant="footer" />
              </div>
            ))}
          </Box>
          <Flex sx={{
            flexDirection: 'column',
            marginLeft: ['unset', 'auto'],
            alignItems: ['center', 'unset'],
            // mt: ['20px', 0],
            textAlign: ['center', 'right'],
            width: ['100%', 'auto']
          }}>
            <Flex sx={{flexDirection: ['column-reverse', 'column'], mb:['30px', '20px'], fontSize: [14, 16], gap: ['27px', '13px']}}>
              <Flex sx={{flexDirection: ['column', 'row'], fontSize: [14, 16]}}>
                <Text>営業時間 11:00 - 20:00</Text>
                <Text>定休日：日曜・第二第四月曜</Text>
              </Flex>
              <div sx={styles.instagram}>
                <Link path="https://www.instagram.com/marmot_beauty/?hl=ja" target="_blank">
                  <Image src={instagram} alt="logo" sx={{width: '44px'}}/>
                  <Text sx={{fontSize: 16}}>Marmot!Beauty 公式</Text>
                </Link>
              </div>
            </Flex>
            <hr sx={{height: '0.5px', display: ['block', 'none'], backgroundColor: 'white', border: 'none', width: '100%'}}/>
            <Text sx={{mt: ['25px', 'unset'], mb: ['0', '18px'], fontSize: [14, 16]}}>Copyright (C) Tanpan&CO. All Rights Reserved.</Text>
            <div sx={{mt: ['5px', '0']}}>
              <Link path="https://tanpan.co.jp/" target="_blank">
                <Text sx={{display: 'inline', fontSize: [14, 16]}}>
                  www.tanpan.co.jp
                </Text>
              </Link>
            </div>
            <div sx={{mt: ['5px', '18px']}}>
              <Link path="https://tanpan.co.jp/privacy-policy/" target="_blank">
                <Text sx={{display: 'inline', fontSize: [14, 16]}}>
                  Privacy Policy
                </Text>
              </Link>
            </div>
          </Flex>
        </Box>
      </div>
    </Box>
  );
}

const styles = {
  footerTopInner: {
    display: ['flex'],
    flexDirection: ['column', 'row'],
    alignItems: ['center', 'start']
  },
  footerInner: {
    borderTop: `1px solid #D9E0E7`,
    display: ['block', null, 'flex'],
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '35px 0 40px',
    '@media only screen and (max-width: 400px)': {
      pb: 10,
    },
  },
  copyright: {
    display: ['flex'],
    alignItems: 'center',
    flexDirection: ['column', null, null, null, 'row'],
    span: {
      fontSize: '14px',
      lineHeight: 1.29,
      color: rgba('#0F2137', 0.6),
      mt: ['18px', '18px', '7px'],
    },
  },
  footerNav: {
    listStyle: 'none',
    // margin: ['15px 0 0', '15px 0 0', '0'],
    marginLeft: ['0', '156px'],
    padding: 0,
    display: ['flex'],
    // gridTemplateColumns: ['1fr 1fr', null],
    width: ['100%', 'auto'],
    marginTop: ['50px', '0'],
    flexDirection: ['column'],
    flexWrap: ['wrap', null, null, 'unset'],
    justifyContent: ['start', null, 'flex-start'],
    height: ['190px', '150px'],
    paddingLeft: ['60px', 0],
    a: {
      color: '#62788D',
      fontSize: ['20px', '17px'],
      mb: ['30px', '20px'],
      lineHeight: '1.25em',
    },
  },
  instagram: {
    display: 'flex',
    justifyContent: ['center', 'flex-end'],
    a: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: ['center', 'flex-end'],
      gap: '14px',
    }
  }
};
